import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import logo from "./logo.svg"; // Make sure to import your logo

function App() {
	const [isAdmin, setIsAdmin] = useState(false);
	const [licenses, setLicenses] = useState([]);
	const [token, setToken] = useState(null);
	const [discordId, setDiscordId] = useState("");
	const [username, setUsername] = useState("");
	const [expirationDays, setExpirationDays] = useState("");

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const token = params.get("token");
		const error = params.get("error");

		if (token) {
			setToken(token);
			verifyToken(token);
		} else if (error) {
			console.error("Error during authentication:", error);
		}
	}, []);

	const decodeToken = (token) => {
		try {
			const base64Url = token.split(".")[1];
			const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
			const jsonPayload = decodeURIComponent(
				atob(base64)
					.split("")
					.map(function (c) {
						return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join("")
			);
			return JSON.parse(jsonPayload);
		} catch (error) {
			console.error("Failed to decode token:", error);
			return null;
		}
	};

	const verifyToken = async (token) => {
		try {
			const decoded = decodeToken(token);
			if (!decoded) {
				throw new Error("Invalid token");
			}
			const discordId = decoded.discordId;

			const response = await axios.get(
				`https://license-backend-azure.vercel.app/admin?token=${token}`
			);

			if (response.data.isAdmin) {
				setIsAdmin(true);
				setLicenses(response.data.licenses);
			}
		} catch (error) {
			console.error("You are not authorized to access this route.");
		}
	};

	const handleLogin = () => {
		window.location.href =
			"https://license-backend-azure.vercel.app/admin/auth/discord";
	};

	const handleCreateLicense = async () => {
		try {
			const response = await axios.post(
				`https://license-backend-azure.vercel.app/admin/create-license?token=${token}`,
				{ discordId, username, expirationDays: parseInt(expirationDays, 10) }
			);
			if (response.data.status === "ok") {
				setLicenses([...licenses, response.data.license]);
				setDiscordId("");
				setUsername("");
				setExpirationDays("");
			}
		} catch (error) {
			console.error("Failed to create license:", error);
		}
	};

	const handleDeleteLicense = async (licenseId) => {
		try {
			const response = await axios.delete(
				`https://license-backend-azure.vercel.app/admin/delete-license/${licenseId}?token=${token}`
			);
			if (response.data.status === "ok") {
				setLicenses(licenses.filter((license) => license._id !== licenseId));
			}
		} catch (error) {
			console.error("Failed to delete license:", error);
		}
	};

	const handleResetMachineId = async (licenseId) => {
		try {
			const response = await axios.post(
				`https://license-backend-azure.vercel.app/admin/reset-machine-id/${licenseId}?token=${token}`
			);
			if (response.data.status === "ok") {
				setLicenses(
					licenses.map((license) =>
						license._id === licenseId
							? { ...license, machineId: null }
							: license
					)
				);
			}
		} catch (error) {
			console.error("Failed to reset machine ID:", error);
		}
	};

	const calculateDaysLeft = (expirationDate) => {
		const now = new Date();
		const expiration = new Date(expirationDate);
		const timeDiff = expiration - now;
		const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
		return daysLeft;
	};

	return (
		<div className="App min-h-screen bg-black text-white">
			{!isAdmin ? (
				<div className="flex flex-col min-h-screen">
					<main className="flex-grow container mx-auto p-4 flex flex-col items-center justify-center text-center">
						<h1 className="text-4xl font-bold mb-8 whitespace-nowrap">
							Resoled Admin Panel
						</h1>
						<button
							onClick={handleLogin}
							className="bg-[#7289DA] hover:bg-[#5e77d4] text-white font-bold py-3 px-8 rounded transition duration-300 whitespace-nowrap min-w-[200px]"
						>
							Login with Discord
						</button>
					</main>
				</div>
			) : (
				<div className="container mx-auto p-5 overflow-y-auto max-h-screen">
					<h1 className="text-2xl mb-4">Admin Panel</h1>
					<div className="mb-4">
						<h2 className="text-xl mb-2">Create License</h2>
						<input
							type="text"
							placeholder="Discord ID"
							value={discordId}
							onChange={(e) => setDiscordId(e.target.value)}
							className="bg-black text-white border border-white p-2 mr-2 mb-2 w-full rounded-md"
						/>
						<input
							type="text"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
							className="bg-black text-white border border-white p-2 mr-2 mb-2 w-full rounded-md"
						/>
						<input
							type="number"
							placeholder="Expiration Days"
							value={expirationDays}
							onChange={(e) => setExpirationDays(e.target.value)}
							className="bg-black text-white border border-white p-2 mr-2 mb-2 w-full rounded-md"
						/>
						<button
							onClick={handleCreateLicense}
							className="bg-[#1a1a1a] text-white border border-white p-2 cursor-pointer hover:bg-[#333333] transition-colors duration-300 rounded-md"
						>
							Create License
						</button>
					</div>
					<h2 className="text-xl mb-2">Licenses</h2>
					<ul>
						<li className="bg-gradient-to-b from-[#1A1919] to-black text-white rounded-lg p-5 mb-2">
							Username - License - Days Left
						</li>
						{licenses.map((license) => (
							<li
								key={license._id}
								className="bg-gradient-to-b from-[#1A1919] to-black text-white rounded-lg p-5 mb-2 flex items-center justify-between"
							>
								<div>
									{license.username} - {license.licenseKey} -{" "}
									{calculateDaysLeft(license.expiresAt)} days
								</div>
								<div>
									<button
										onClick={() => handleDeleteLicense(license._id)}
										className="bg-[#1a1a1a] text-white border border-white p-2 cursor-pointer hover:bg-[#333333] transition-colors duration-300 mr-2 rounded-md"
									>
										Delete
									</button>
									<button
										onClick={() => handleResetMachineId(license._id)}
										className="bg-[#1a1a1a] text-white border border-white p-2 cursor-pointer hover:bg-[#333333] transition-colors duration-300 rounded-md"
									>
										Reset Machine ID
									</button>
								</div>
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
}

export default App;
